import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import cinema from "../../Assets/Projects/cinema.png";
import glasses from "../../Assets/Projects/glasses.png";
import laptop from "../../Assets/Projects/laptop.png";
import Particle from "../Particle";
import ProjectCard from "./ProjectCards";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col sm={12} md={12} lg={4} className="project-card">
            <ProjectCard
              imgPath={cinema}
              isBlog={false}
              title="Website Cinema (NextJs, Spring Boot, AWS, PostgreSQL)"
              ghLink="https://zuhot.id.vn/"
            />
          </Col>
          <Col sm={12} md={12} lg={4} className="project-card">
            <ProjectCard
              imgPath={laptop}
              isBlog={false}
              title="Website Laptop (ReactJS, Spring Boot, PostgreSQL)"
              ghLink="https://zuhot-laptop.vercel.app/"
              ghBE="https://github.com/anbvt/Laptop_eCommerce_BE"
              ghFE="https://github.com/anbvt/Laptop_eCommerce_FE"
            />
          </Col>
          <Col sm={12} md={12} lg={4} className="project-card">
            <ProjectCard
              imgPath={glasses}
              isBlog={false}
              title="Website Glasses (Spring Boot (Spring MVC, Spring Data JPA, Interceptor, Hibernate, Thymeleaf), PostgreSQL)"
              ghGithub="https://github.com/anbvt/Glasses-Stores"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
