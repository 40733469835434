import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import Preloader from "../src/components/Pre";
import "./App.css";
import Achievements from "./components/Achievements/Achievements";
import Certificate from "./components/Certificate/Certificate";
import Footer from "./components/Footer";
import About from "./components/Home/About";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects/Projects";
import ResumeNew from "./components/Resume/Resume";
import ScrollToTop from "./components/ScrollToTop";
import Transcript from "./components/Transcript/Transcript";
import "./style.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/resume" element={<ResumeNew />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/certificate" element={<Certificate />} />
          <Route path="/transcript" element={<Transcript />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
