import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <h1 className="project-heading">
        <strong className="purple">About </strong> Me
      </h1>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "center" }}>
            Hi Everyone, I am <span className="purple">Bùi Vũ Thiên Ân </span>
            from <span className="purple"> Bien Hoa City, Viet Nam.</span>
            <br />
            I have experience in <span className="purple">Java</span>, <span className="purple">Spring Boot</span>, <span className="purple">ReactJS</span>, and <span className="purple">NextJS</span> projects.
            <br />
            With a dynamic and enthusiastic personality, I excel at exploration, self-learning, and skill enhancement.
            <br />
            <br />
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
