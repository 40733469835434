import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FALL2022 from "../../Assets/Achievements/FALL2022.png";
import SPRING2022 from "../../Assets/Achievements/SPRING2022.png";
import SPRING2023 from "../../Assets/Achievements/SPRING2023.png";
import SUMMER2022 from "../../Assets/Achievements/SUMMER2022.png";
import SUMMER2023 from "../../Assets/Achievements/SUMMER2023.png";
import Particle from "../Particle";
import ProjectCards from "../Projects/ProjectCards";
function Achievements() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Achievements </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a my achievements.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md="4" className="p-3">
            <ProjectCards
              imgPath={SUMMER2023}
            />
          </Col>
          <Col md="4" className="p-3">
            <ProjectCards
              imgPath={SPRING2023}
            />
          </Col>
          <Col md="4" className="p-3">
            <ProjectCards
              imgPath={FALL2022}
            />
          </Col>
          <Col md="4" className="p-3">
            <ProjectCards
              imgPath={SUMMER2022}
            />
          </Col>
          <Col md="4" className="p-3">
            <ProjectCards
              imgPath={SPRING2022}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Achievements;
