import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { BsGithub, BsTv } from "react-icons/bs";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      {props.title ? <Card.Body>
        <Card.Title style={{ fontFamily: "sans-serif" }}>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {"\n"}
        {"\n"}
        {props.ghGithub && (
          <Button variant="primary" href={props.ghGithub} target="_blank" style={{ marginTop: 10 }}>
            <BsGithub /> &nbsp;
            {"Github"}
          </Button>
        )}
        {"\n"}
        {"\n"}
        {props.ghFE && (
          <Button variant="primary" href={props.ghFE} target="_blank" style={{ marginTop: 10 }}>
            <BsGithub /> &nbsp;
            {"Front-end"}
          </Button>
        )}
        {"\n"}
        {"\n"}
        {props.ghBE && (
          <Button variant="primary" href={props.ghBE} target="_blank" style={{ marginTop: 10 }}>
            <BsGithub /> &nbsp;
            {"Back-end"}
          </Button>
        )}
        {"\n"}
        {"\n"}
        {props.ghLink && (
          <Button variant="primary" href={props.ghLink} target="_blank" style={{ marginTop: 10 }}>
            <BsTv /> &nbsp;
            {"Review"}
          </Button>
        )}
      </Card.Body>
      : "" }
    </Card>
  );
}
export default ProjectCards;
